const NAMES = [
 'Aaron',
 'Adam',
 'Adrian',
 'Adriano',
 'Ahmad',
 'Ahmed',
 'Ahmet',
 'Alan',
 'Albert',
 'Alessandro',
 'Alessio',
 'Alex',
 'Alexander',
 'Alfred',
 'Ali',
 'Amar',
 'Amir',
 'Amon',
 'Andre',
 'Andreas',
 'Andrew',
 'Angelo',
 'Ansgar',
 'Anthony',
 'Anton',
 'Antonio',
 'Arda',
 'Arian',
 'Armin',
 'Arne',
 'Arno',
 'Arthur',
 'Artur',
 'Arved',
 'Arvid',
 'Ayman',
 'Baran',
 'Baris',
 'Bastian',
 'Batuhan',
 'Bela',
 'Ben',
 'Benedikt',
 'Benjamin',
 'Bennet',
 'Bennett',
 'Benno',
 'Bent',
 'Berat',
 'Berkay',
 'Bernd',
 'Bilal',
 'Bjarne',
 'Björn',
 'Bo',
 'Boris',
 'Brandon',
 'Brian',
 'Bruno',
 'Bryan',
 'Burak',
 'Calvin',
 'Can',
 'Carl',
 'Carlo',
 'Carlos',
 'Caspar',
 'Cedric',
 'Cedrik',
 'Cem',
 'Charlie',
 'Chris',
 'Christian',
 'Christiano',
 'Christoph',
 'Christopher',
 'Claas',
 'Clemens',
 'Colin',
 'Collin',
 'Conner',
 'Connor',
 'Constantin',
 'Corvin',
 'Curt',
 'Damian',
 'Damien',
 'Daniel',
 'Danilo',
 'Danny',
 'Darian',
 'Dario',
 'Darius',
 'Darren',
 'David',
 'Davide',
 'Davin',
 'Dean',
 'Deniz',
 'Dennis',
 'Denny',
 'Devin',
 'Diego',
 'Dion',
 'Domenic',
 'Domenik',
 'Dominic',
 'Dominik',
 'Dorian',
 'Dustin',
 'Dylan',
 'Ecrin',
 'Eddi',
 'Eddy',
 'Edgar',
 'Edwin',
 'Efe',
 'Ege',
 'Elia',
 'Eliah',
 'Elias',
 'Elijah',
 'Emanuel',
 'Emil',
 'Emilian',
 'Emilio',
 'Emir',
 'Emirhan',
 'Emre',
 'Enes',
 'Enno',
 'Enrico',
 'Eren',
 'Eric',
 'Erik',
 'Etienne',
 'Fabian',
 'Fabien',
 'Fabio',
 'Fabrice',
 'Falk',
 'Felix',
 'Ferdinand',
 'Fiete',
 'Filip',
 'Finlay',
 'Finley',
 'Finn',
 'Finnley',
 'Florian',
 'Francesco',
 'Franz',
 'Frederic',
 'Frederick',
 'Frederik',
 'Friedrich',
 'Fritz',
 'Furkan',
 'Fynn',
 'Gabriel',
 'Georg',
 'Gerrit',
 'Gian',
 'Gianluca',
 'Gino',
 'Giuliano',
 'Giuseppe',
 'Gregor',
 'Gustav',
 'Hagen',
 'Hamza',
 'Hannes',
 'Hanno',
 'Hans',
 'Hasan',
 'Hassan',
 'Hauke',
 'Hendrik',
 'Hennes',
 'Henning',
 'Henri',
 'Henrick',
 'Henrik',
 'Henry',
 'Hugo',
 'Hussein',
 'Ian',
 'Ibrahim',
 'Ilias',
 'Ilja',
 'Ilyas',
 'Immanuel',
 'Ismael',
 'Ismail',
 'Ivan',
 'Iven',
 'Jack',
 'Jacob',
 'Jaden',
 'Jakob',
 'Jamal',
 'James',
 'Jamie',
 'Jan',
 'Janek',
 'Janis',
 'Janne',
 'Jannek',
 'Jannes',
 'Jannik',
 'Jannis',
 'Jano',
 'Janosch',
 'Jared',
 'Jari',
 'Jarne',
 'Jarno',
 'Jaron',
 'Jason',
 'Jasper',
 'Jay',
 'Jayden',
 'Jayson',
 'Jean',
 'Jens',
 'Jeremias',
 'Jeremie',
 'Jeremy',
 'Jermaine',
 'Jerome',
 'Jesper',
 'Jesse',
 'Jim',
 'Jimmy',
 'Joe',
 'Joel',
 'Joey',
 'Johann',
 'Johannes',
 'John',
 'Johnny',
 'Jon',
 'Jona',
 'Jonah',
 'Jonas',
 'Jonathan',
 'Jonte',
 'Joost',
 'Jordan',
 'Joris',
 'Joscha',
 'Joschua',
 'Josef',
 'Joseph',
 'Josh',
 'Joshua',
 'Josua',
 'Juan',
 'Julian',
 'Julien',
 'Julius',
 'Juri',
 'Justin',
 'Justus',
 'Kaan',
 'Kai',
 'Kalle',
 'Karim',
 'Karl',
 'Karlo',
 'Kay',
 'Keanu',
 'Kenan',
 'Kenny',
 'Keno',
 'Kerem',
 'Kerim',
 'Kevin',
 'Kian',
 'Kilian',
 'Kim',
 'Kimi',
 'Kjell',
 'Klaas',
 'Klemens',
 'Konrad',
 'Konstantin',
 'Koray',
 'Korbinian',
 'Kurt',
 'Lars',
 'Lasse',
 'Laurence',
 'Laurens',
 'Laurenz',
 'Laurin',
 'Lean',
 'Leander',
 'Leandro',
 'Leif',
 'Len',
 'Lenn',
 'Lennard',
 'Lennart',
 'Lennert',
 'Lennie',
 'Lennox',
 'Lenny',
 'Leo',
 'Leon',
 'Leonard',
 'Leonardo',
 'Leonhard',
 'Leonidas',
 'Leopold',
 'Leroy',
 'Levent',
 'Levi',
 'Levin',
 'Lewin',
 'Lewis',
 'Liam',
 'Lian',
 'Lias',
 'Lino',
 'Linus',
 'Lio',
 'Lion',
 'Lionel',
 'Logan',
 'Lorenz',
 'Lorenzo',
 'Loris',
 'Louis',
 'Luan',
 'Luc',
 'Luca',
 'Lucas',
 'Lucian',
 'Lucien',
 'Ludwig',
 'Luis',
 'Luiz',
 'Luk',
 'Luka',
 'Lukas',
 'Luke',
 'Lutz',
 'Maddox',
 'Mads',
 'Magnus',
 'Maik',
 'Maksim',
 'Malik',
 'Malte',
 'Manuel',
 'Marc',
 'Marcel',
 'Marco',
 'Marcus',
 'Marek',
 'Marian',
 'Mario',
 'Marius',
 'Mark',
 'Marko',
 'Markus',
 'Marlo',
 'Marlon',
 'Marten',
 'Martin',
 'Marvin',
 'Marwin',
 'Mateo',
 'Mathis',
 'Matis',
 'Mats',
 'Matteo',
 'Mattes',
 'Matthias',
 'Matthis',
 'Matti',
 'Mattis',
 'Maurice',
 'Max',
 'Maxim',
 'Maximilian',
 'Mehmet',
 'Meik',
 'Melvin',
 'Merlin',
 'Mert',
 'Michael',
 'Michel',
 'Mick',
 'Miguel',
 'Mika',
 'Mikail',
 'Mike',
 'Milan',
 'Milo',
 'Mio',
 'Mirac',
 'Mirco',
 'Mirko',
 'Mohamed',
 'Mohammad',
 'Mohammed',
 'Moritz',
 'Morten',
 'Muhammed',
 'Murat',
 'Mustafa',
 'Nathan',
 'Nathanael',
 'Nelson',
 'Neo',
 'Nevio',
 'Nick',
 'Niclas',
 'Nico',
 'Nicolai',
 'Nicolas',
 'Niels',
 'Nikita',
 'Niklas',
 'Niko',
 'Nikolai',
 'Nikolas',
 'Nils',
 'Nino',
 'Noah',
 'Noel',
 'Norman',
 'Odin',
 'Oke',
 'Ole',
 'Oliver',
 'Omar',
 'Onur',
 'Oscar',
 'Oskar',
 'Pascal',
 'Patrice',
 'Patrick',
 'Paul',
 'Peer',
 'Pepe',
 'Peter',
 'Phil',
 'Philip',
 'Philipp',
 'Pierre',
 'Piet',
 'Pit',
 'Pius',
 'Quentin',
 'Quirin',
 'Rafael',
 'Raik',
 'Ramon',
 'Raphael',
 'Rasmus',
 'Raul',
 'Rayan',
 'René',
 'Ricardo',
 'Riccardo',
 'Richard',
 'Rick',
 'Rico',
 'Robert',
 'Robin',
 'Rocco',
 'Roman',
 'Romeo',
 'Ron',
 'Ruben',
 'Ryan',
 'Said',
 'Salih',
 'Sam',
 'Sami',
 'Sammy',
 'Samuel',
 'Sandro',
 'Santino',
 'Sascha',
 'Sean',
 'Sebastian',
 'Selim',
 'Semih',
 'Shawn',
 'Silas',
 'Simeon',
 'Simon',
 'Sinan',
 'Sky',
 'Stefan',
 'Steffen',
 'Stephan',
 'Steve',
 'Steven',
 'Sven',
 'Sönke',
 'Sören',
 'Taha',
 'Tamino',
 'Tammo',
 'Tarik',
 'Tayler',
 'Taylor',
 'Teo',
 'Theo',
 'Theodor',
 'Thies',
 'Thilo',
 'Thomas',
 'Thorben',
 'Thore',
 'Thorge',
 'Tiago',
 'Til',
 'Till',
 'Tillmann',
 'Tim',
 'Timm',
 'Timo',
 'Timon',
 'Timothy',
 'Tino',
 'Titus',
 'Tizian',
 'Tjark',
 'Tobias',
 'Tom',
 'Tommy',
 'Toni',
 'Tony',
 'Torben',
 'Tore',
 'Tristan',
 'Tyler',
 'Tyron',
 'Umut',
 'Valentin',
 'Valentino',
 'Veit',
 'Victor',
 'Viktor',
 'Vin',
 'Vincent',
 'Vito',
 'Vitus',
 'Wilhelm',
 'Willi',
 'William',
 'Willy',
 'Xaver',
 'Yannic',
 'Yannick',
 'Yannik',
 'Yannis',
 'Yasin',
 'Youssef',
 'Yunus',
 'Yusuf',
 'Yven',
 'Yves',
 'Ömer',
 'Aaliyah',
 'Abby',
 'Abigail',
 'Ada',
 'Adelina',
 'Adriana',
 'Aileen',
 'Aimee',
 'Alana',
 'Alea',
 'Alena',
 'Alessa',
 'Alessia',
 'Alexa',
 'Alexandra',
 'Alexia',
 'Alexis',
 'Aleyna',
 'Alia',
 'Alica',
 'Alice',
 'Alicia',
 'Alina',
 'Alisa',
 'Alisha',
 'Alissa',
 'Aliya',
 'Aliyah',
 'Allegra',
 'Alma',
 'Alyssa',
 'Amalia',
 'Amanda',
 'Amelia',
 'Amelie',
 'Amina',
 'Amira',
 'Amy',
 'Ana',
 'Anabel',
 'Anastasia',
 'Andrea',
 'Angela',
 'Angelina',
 'Angelique',
 'Anja',
 'Ann',
 'Anna',
 'Annabel',
 'Annabell',
 'Annabelle',
 'Annalena',
 'Anne',
 'Anneke',
 'Annelie',
 'Annemarie',
 'Anni',
 'Annie',
 'Annika',
 'Anny',
 'Anouk',
 'Antonia',
 'Ariana',
 'Ariane',
 'Arwen',
 'Ashley',
 'Asya',
 'Aurelia',
 'Aurora',
 'Ava',
 'Ayleen',
 'Aylin',
 'Ayse',
 'Azra',
 'Betty',
 'Bianca',
 'Bianka',
 'Caitlin',
 'Cara',
 'Carina',
 'Carla',
 'Carlotta',
 'Carmen',
 'Carolin',
 'Carolina',
 'Caroline',
 'Cassandra',
 'Catharina',
 'Catrin',
 'Cecile',
 'Cecilia',
 'Celia',
 'Celina',
 'Celine',
 'Ceyda',
 'Ceylin',
 'Chantal',
 'Charleen',
 'Charlotta',
 'Charlotte',
 'Chayenne',
 'Cheyenne',
 'Chiara',
 'Christin',
 'Christina',
 'Cindy',
 'Claire',
 'Clara',
 'Clarissa',
 'Colleen',
 'Collien',
 'Cora',
 'Corinna',
 'Cosima',
 'Dana',
 'Daniela',
 'Daria',
 'Darleen',
 'Defne',
 'Delia',
 'Denise',
 'Diana',
 'Dilara',
 'Dina',
 'Dorothea',
 'Eda',
 'Eileen',
 'Ela',
 'Elaine',
 'Elanur',
 'Elea',
 'Elena',
 'Eleni',
 'Eleonora',
 'Eliana',
 'Elif',
 'Elina',
 'Elisa',
 'Elisabeth',
 'Ella',
 'Ellen',
 'Elli',
 'Elly',
 'Elsa',
 'Emelie',
 'Emely',
 'Emilia',
 'Emilie',
 'Emily',
 'Emma',
 'Emmely',
 'Emmi',
 'Emmy',
 'Enie',
 'Enna',
 'Enya',
 'Esma',
 'Estelle',
 'Esther',
 'Eva',
 'Evelin',
 'Evelina',
 'Eveline',
 'Evelyn',
 'Fabienne',
 'Fatima',
 'Fatma',
 'Felicia',
 'Felicitas',
 'Felina',
 'Femke',
 'Fenja',
 'Fine',
 'Finia',
 'Finja',
 'Finnja',
 'Fiona',
 'Flora',
 'Florentine',
 'Francesca',
 'Franka',
 'Franziska',
 'Frederike',
 'Freya',
 'Frida',
 'Frieda',
 'Friederike',
 'Giada',
 'Gina',
 'Giulia',
 'Giuliana',
 'Greta',
 'Hailey',
 'Hana',
 'Hanna',
 'Hannah',
 'Heidi',
 'Helen',
 'Helena',
 'Helene',
 'Helin',
 'Henriette',
 'Henrike',
 'Hermine',
 'Ida',
 'Ilayda',
 'Imke',
 'Ina',
 'Ines',
 'Inga',
 'Inka',
 'Irem',
 'Isa',
 'Isabel',
 'Isabell',
 'Isabella',
 'Isabelle',
 'Ivonne',
 'Jacqueline',
 'Jamila',
 'Jana',
 'Jane',
 'Janin',
 'Janina',
 'Janine',
 'Janna',
 'Jara',
 'Jasmin',
 'Jasmina',
 'Jasmine',
 'Jella',
 'Jenna',
 'Jennifer',
 'Jenny',
 'Jessica',
 'Jessy',
 'Jette',
 'Jil',
 'Jill',
 'Joana',
 'Joanna',
 'Joelina',
 'Joeline',
 'Joelle',
 'Johanna',
 'Joleen',
 'Jolie',
 'Jolien',
 'Jolin',
 'Jolina',
 'Joline',
 'Jonna',
 'Josefin',
 'Josefine',
 'Josephin',
 'Josephine',
 'Josie',
 'Josy',
 'Joy',
 'Joyce',
 'Judith',
 'Judy',
 'Jule',
 'Julia',
 'Juliana',
 'Juliane',
 'Julie',
 'Julienne',
 'Julika',
 'Julina',
 'Juna',
 'Justine',
 'Kaja',
 'Karina',
 'Karla',
 'Karlotta',
 'Karolina',
 'Karoline',
 'Kassandra',
 'Katarina',
 'Katharina',
 'Kathrin',
 'Katja',
 'Katrin',
 'Kaya',
 'Kayra',
 'Kiana',
 'Kiara',
 'Kimberley',
 'Kimberly',
 'Kira',
 'Klara',
 'Korinna',
 'Kristin',
 'Kyra',
 'Laila',
 'Lana',
 'Lara',
 'Larissa',
 'Laura',
 'Laureen',
 'Lavinia',
 'Lea',
 'Leah',
 'Leana',
 'Leandra',
 'Leann',
 'Lee',
 'Leila',
 'Lena',
 'Lene',
 'Leni',
 'Lenia',
 'Lenja',
 'Lenya',
 'Leona',
 'Leoni',
 'Leonie',
 'Leonora',
 'Leticia',
 'Letizia',
 'Levke',
 'Leyla',
 'Lia',
 'Liah',
 'Liana',
 'Lili',
 'Lilia',
 'Lilian',
 'Liliana',
 'Lilith',
 'Lilli',
 'Lillian',
 'Lilly',
 'Lily',
 'Lina',
 'Linda',
 'Lindsay',
 'Line',
 'Linn',
 'Linnea',
 'Lisa',
 'Lisann',
 'Lisanne',
 'Liv',
 'Livia',
 'Liz',
 'Lola',
 'Loreen',
 'Lorena',
 'Lotta',
 'Lotte',
 'Louisa',
 'Louise',
 'Luana',
 'Lucia',
 'Lucie',
 'Lucienne',
 'Lucy',
 'Luisa',
 'Luise',
 'Luna',
 'Luzie',
 'Lya',
 'Lydia',
 'Lyn',
 'Lynn',
 'Madeleine',
 'Madita',
 'Madleen',
 'Madlen',
 'Magdalena',
 'Maike',
 'Mailin',
 'Maira',
 'Maja',
 'Malena',
 'Malia',
 'Malin',
 'Malina',
 'Mandy',
 'Mara',
 'Marah',
 'Mareike',
 'Maren',
 'Maria',
 'Mariam',
 'Marie',
 'Marieke',
 'Mariella',
 'Marika',
 'Marina',
 'Marisa',
 'Marissa',
 'Marit',
 'Marla',
 'Marleen',
 'Marlen',
 'Marlena',
 'Marlene',
 'Marta',
 'Martha',
 'Mary',
 'Maryam',
 'Mathilda',
 'Mathilde',
 'Matilda',
 'Maxi',
 'Maxima',
 'Maxine',
 'Maya',
 'Mayra',
 'Medina',
 'Medine',
 'Meike',
 'Melanie',
 'Melek',
 'Melike',
 'Melina',
 'Melinda',
 'Melis',
 'Melisa',
 'Melissa',
 'Merle',
 'Merve',
 'Meryem',
 'Mette',
 'Mia',
 'Michaela',
 'Michelle',
 'Mieke',
 'Mila',
 'Milana',
 'Milena',
 'Milla',
 'Mina',
 'Mira',
 'Miray',
 'Miriam',
 'Mirja',
 'Mona',
 'Monique',
 'Nadine',
 'Nadja',
 'Naemi',
 'Nancy',
 'Naomi',
 'Natalia',
 'Natalie',
 'Nathalie',
 'Neele',
 'Nela',
 'Nele',
 'Nelli',
 'Nelly',
 'Nia',
 'Nicole',
 'Nika',
 'Nike',
 'Nila',
 'Nina',
 'Nisa',
 'Noemi',
 'Nora',
 'Olivia',
 'Patricia',
 'Patrizia',
 'Paula',
 'Paulina',
 'Pauline',
 'Penelope',
 'Philine',
 'Phoebe',
 'Pia',
 'Rahel',
 'Rania',
 'Rebecca',
 'Rebekka',
 'Riana',
 'Rieke',
 'Rike',
 'Romina',
 'Romy',
 'Ronja',
 'Rosa',
 'Rosalie',
 'Ruby',
 'Sabrina',
 'Sahra',
 'Sally',
 'Salome',
 'Samantha',
 'Samia',
 'Samira',
 'Sandra',
 'Sandy',
 'Sanja',
 'Saphira',
 'Sara',
 'Sarah',
 'Saskia',
 'Selin',
 'Selina',
 'Selma',
 'Sena',
 'Sidney',
 'Sienna',
 'Silja',
 'Sina',
 'Sinja',
 'Smilla',
 'Sofia',
 'Sofie',
 'Sonja',
 'Sophia',
 'Sophie',
 'Soraya',
 'Stefanie',
 'Stella',
 'Stephanie',
 'Stina',
 'Sude',
 'Summer',
 'Susanne',
 'Svea',
 'Svenja',
 'Sydney',
 'Tabea',
 'Talea',
 'Talia',
 'Tamara',
 'Tamia',
 'Tamina',
 'Tanja',
 'Tara',
 'Tarja',
 'Teresa',
 'Tessa',
 'Thalea',
 'Thalia',
 'Thea',
 'Theresa',
 'Tia',
 'Tina',
 'Tomke',
 'Tuana',
 'Valentina',
 'Valeria',
 'Valerie',
 'Vanessa',
 'Vera',
 'Veronika',
 'Victoria',
 'Viktoria',
 'Viola',
 'Vivian',
 'Vivien',
 'Vivienne',
 'Wibke',
 'Wiebke',
 'Xenia',
 'Yara',
 'Yaren',
 'Yasmin',
 'Yvonne',
 'Zara',
 'Zehra',
 'Zeynep',
 'Zoe',
 'Zoey',
];


const generateUsername = (): string => {
    const idx = Math.floor(Math.random() * NAMES.length);
    return NAMES[idx] + Math.floor(Math.random() * 100);
}



const generatePassword = (length: number = 12): string =>  {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; i++) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

const TextGenerator = {generatePassword, generateUsername};
export default TextGenerator;